import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "bootstrap/dist/css/bootstrap.min.css"

let styles = {
  image: {
    maxWidth: '350px',
    display: `block`,
    marginLeft: `auto`,
    marginRight: `auto`
  },
  titleText: {
    display: `block`,
    marginLeft: `auto`,
    marginRight: `auto`,
    textAlign: 'center',
    fontSize: '4em'
  },
  urlText: {
    display: `block`,
    marginLeft: `auto`,
    marginRight: `auto`,
    textAlign: 'center',
    fontSize: '2em'
  },
  div: {
    minHeight: `100vh`,
  }
}

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <img
      src="https://res.cloudinary.com/pgoodjohn/image/upload/v1507573028/MattiaSerchione/Assets/logo.png"
      style={styles.image}
    />
    <h1
      style={styles.titleText}
    >
      MUSIC PRODUCTION
    </h1>
    <a
      href="#"
      style={styles.urlText}
    >
      mattiaserchione.it
    </a>
  </Layout>
)

export default IndexPage
